import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { type TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { merge } from 'lodash';
import { themeOptions as lightTheme } from 'muiTheme/lightTheme';
import React from 'react';
import '../muiTheme/fonts.css';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    labelSmall: TypographyStyleOptions;
  }

  interface TypographyVariantsOptions {
    labelSmall?: TypographyStyleOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    extended: true;
    contained: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    labelSmall: true;
  }
}

export const fenixThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Gellix',
    h1: {
      fontFamily: 'Gellix',
      fontSize: '6rem',
      fontWeight: 400,
      lineHeight: '125%',
      letterSpacing: '1.5px',
    },
    h2: {
      fontFamily: 'Gellix',
      fontSize: '3.75rem',
      fontWeight: 400,
      lineHeight: '125%',
      letterSpacing: '0.5px',
    },
    h3: {
      fontFamily: 'Gellix',
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: '125%',
      letterSpacing: '0.5px',
    },
    h4: {
      fontFamily: 'Gellix',
      fontSize: '2.124rem',
      fontWeight: 400,
      lineHeight: '123.5%',
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: 'Gellix',
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '133.4%',
      letterSpacing: '0.25px',
    },
    h6: {
      fontFamily: 'Gellix',
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '160%',
      letterSpacing: '0.25px',
    },
    body1: {
      fontFamily: 'Gellix',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
    body2: {
      fontFamily: 'Gellix',
      fontSize: '0.8125rem',
      fontWeight: 400,
      lineHeight: '143%',
      letterSpacing: '0.5px',
    },
    subtitle1: {
      fontFamily: 'Gellix',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '175%',
      letterSpacing: '0.15px',
    },
    labelSmall: {
      fontFamily: 'Gellix',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1rem',
      letterSpacing: '0.01rem',
    },
  },
  palette: {
    text: {
      secondary: 'rgba(0, 40, 58, 0.6)',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          labelSmall: 'span',
        },
      },
      styleOverrides: {
        subtitle2: {
          fontSize: '0.875rem',
          fontWeight: '600',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: '#00344a',
          color: 'white',
          fontWeight: '600',
          gap: '12px',
        },
      },
      variants: [
        {
          props: { variant: 'extended' },
          style: {
            width: '232px',
            height: '56px',
            borderRadius: '16px',
            textTransform: 'capitalize',
            boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
            '&:hover': {
              background: '#00344a',
              boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
            },
            '&:active': {
              background: '#00344a',
              boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: '100px',
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              background: '#00344a',
              boxShadow: 'none',
            },
            '&:active': {
              background: '#00344a',
              boxShadow: 'none',
            },
          },
        },
      ],
    },
  },
};

const fenixTheme = createTheme(merge(fenixThemeOptions, lightTheme));

export const FenixThemeProvider = ({ children }: React.PropsWithChildren) => <ThemeProvider theme={fenixTheme}>{children}</ThemeProvider>;
