import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Tabs, TabsProps } from 'antd';
import { Icon } from 'components';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CINDER_URLS } from 'utils/cinder-urls';
import { FeatureFlags } from 'utils/featureFlags';
import goToCinder from 'utils/goToCinder';
import { CreateDocumentButton } from '../components/create-document-button';
import { DocumentsPage } from '../documents-tab/DocumentsPage';
import { TrashPage } from '../trash-tab/TrashPage';
import './styles.less';

const tabsKeys = {
  DOCUMENTS: 'documents',
  TRASH: 'trash',
  ARCHIVES: 'archives',
};

export function PipelineTabs() {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(tabsKeys.DOCUMENTS);

  const featureFlags = useFeatureFlag([FeatureFlags.editorV3, FeatureFlags.documentsV3]);
  const isEditorV3Enabled = featureFlags[FeatureFlags.editorV3];
  const isDocumentsV3Enabled = featureFlags[FeatureFlags.documentsV3];

  const itemForTrash: TabsProps['items'] = [
    {
      key: tabsKeys.TRASH,
      label: (
        <>
          {<Icon name="IcoTrash" />}
          {t('pipeline.tabs.trash_document_list.title')}
        </>
      ),
      children: <TrashPage setCurrentTab={setCurrentTab} />,
    },
  ];
  const itemForArchives: TabsProps['items'] = [
    {
      key: tabsKeys.ARCHIVES,
      label: (
        <>
          {<Icon name="IcoArchives" />}
          {t('pipeline.tabs.archives.title')}
        </>
      ),
      children: <></>,
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: tabsKeys.DOCUMENTS,
      label: (
        <>
          <span>{<InsertDriveFileOutlinedIcon />}</span>
          {t('pipeline.tabs.document_list.title')}
        </>
      ),
      children: <DocumentsPage />,
    },
    ...(!isDocumentsV3Enabled ? [] : itemForArchives),
    ...(isEditorV3Enabled ? itemForTrash : []),
  ];

  return (
    <div className="pipeline-tabs">
      <Tabs
        tabPosition={'left'}
        items={items}
        destroyInactiveTabPane={true}
        activeKey={currentTab}
        onChange={(activeTab) => {
          if (activeTab === tabsKeys.ARCHIVES) {
            goToCinder(CINDER_URLS.archives);
            return;
          }
          setSearchParams({});
          setCurrentTab(activeTab);
        }}
        tabBarExtraContent={{ left: currentTab !== tabsKeys.TRASH ? <CreateDocumentButton /> : null }} //left works as 'top' since we are using tabPosition left
      />
    </div>
  );
}
