import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { type Document } from 'services/documents/entities/Document';
import { ApiEditorRepository } from 'services/repositories/implementations/ApiEditorRepository';
import { type UpdateDocumentStatusPayload } from 'services/repositories/interfaces/EditorRepository';

interface UseManuallyUpdateDocumentStatusProps {
  documentId: string;
  currentDocumentStatus: Document['status'];
  onUpdateSuccess: (newStatus: string) => void;
  onUpdateFailure: () => void;
}

type PossibleDestinationStatuses = Extract<Document['status'], 'Won' | 'Lost' | 'Draft' | 'Sent'>;

const statusMap: Record<PossibleDestinationStatuses, UpdateDocumentStatusPayload['status']> = {
  Won: 'WON',
  Lost: 'LOST',
  Draft: 'DRAFT',
  Sent: 'SENT',
};

const editorRepository = new ApiEditorRepository();

export function useManuallyUpdateDocumentStatus({
  documentId,
  currentDocumentStatus,
  onUpdateSuccess,
  onUpdateFailure,
}: UseManuallyUpdateDocumentStatusProps) {
  const [availableStatuses, setAvailableStatuses] = useState<PossibleDestinationStatuses[]>([]);

  useEffect(() => {
    switch (currentDocumentStatus) {
      case 'Draft':
        setAvailableStatuses(['Sent', 'Won', 'Lost']);
        break;
      case 'Sent':
        setAvailableStatuses(['Draft', 'Won', 'Lost']);
        break;
      case 'Awaiting Signature':
        setAvailableStatuses(['Won', 'Lost']);
        break;
      default:
        setAvailableStatuses([]);
    }
  }, [currentDocumentStatus]);

  const { mutate, isLoading } = useMutation({
    mutationFn: async (payload: UpdateDocumentStatusPayload) => {
      return editorRepository.updateDocumentStatus(documentId, payload);
    },
    onSuccess: async (data) => onUpdateSuccess(data.status.toLowerCase()),
    onError: onUpdateFailure,
  });

  const updateDocumentStatus = (status: Document['status']) => {
    return () => {
      const payload = { status: statusMap[status] };
      mutate(payload);
    };
  };

  return { canEditDocumentStatus: availableStatuses.length > 0, availableStatuses, updateDocumentStatus, isLoading };
}
