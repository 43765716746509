import { Box, List, type SxProps, type Theme } from '@mui/material';
import { useRef } from 'react';

import useFeatureFlag from 'hooks/useFeatureFlag';
import { type Document } from 'services/documents/entities/Document';
import { FeatureFlags } from 'utils/featureFlags';
import { DocumentListItem } from './DocumentListItem';
import { DocumentListItemV2 } from './DocumentListItemV2';
import { DuplicateDocumentAction } from './document-actions/DuplicateDocumentAction';
import { TrashDocumentAction } from './document-actions/TrashDocumentAction';

type DocumentsListProps = {
  documents: Document[];
  setDocumentList: (documents: Document[] | ((prev: Document[]) => Document[])) => Promise<void>;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  onMoveToTrash: (documentId: string) => Promise<void>;
  onRestoreFromTrash: (documentId: string) => Promise<void>;
  refreshDocumentList: () => Promise<void>;
  sx: SxProps<Theme>;
};

export function DocumentsList({
  documents,
  refreshDocumentList,
  setDocumentList,
  setCurrentPage,
  onMoveToTrash,
  onRestoreFromTrash,
  sx,
}: DocumentsListProps) {
  const evaluateFeatureFlag = useFeatureFlag([FeatureFlags.documentStatusesV3]);
  const hasV3DocumentStatusFlag = evaluateFeatureFlag['v3-document-statuses'];

  const scrollRef = useRef<HTMLDivElement>(null);
  const handleScrollToTop = () => scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  const viewNewlyDuplicatedDocument = () => {
    setCurrentPage(1);
    handleScrollToTop();
  };

  return (
    <Box
      ref={scrollRef}
      sx={{
        ...sx,
        borderBottomLeftRadius: '24px',
        borderBottomRightRadius: '24px',
        '& .MuiListItem-root:last-of-type': {
          borderBottomLeftRadius: '24px',
          borderBottomRightRadius: '24px',
        },
      }}
    >
      <List sx={{ padding: 0 }}>
        {documents.map((document) =>
          document.editorVersion === 3 ? (
            <DocumentListItem
              key={document.id}
              document={document}
              enableStatuses={hasV3DocumentStatusFlag}
              refreshDocumentList={refreshDocumentList}
            >
              <DuplicateDocumentAction
                documentToDuplicate={document}
                setDocumentList={setDocumentList}
                viewNewlyDuplicatedDocument={viewNewlyDuplicatedDocument}
              />
              <TrashDocumentAction
                documentToTrash={document}
                setDocumentList={setDocumentList}
                onTrash={onMoveToTrash}
                onRestore={onRestoreFromTrash}
              />
            </DocumentListItem>
          ) : (
            <DocumentListItemV2 key={document.id} document={document} />
          )
        )}
      </List>
    </Box>
  );
}
