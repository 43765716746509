import { Chip, Divider } from '@mui/material';
import { List } from 'antd';
import { Tooltip } from 'components';
import { Avatar } from 'components/avatar';
import { useCurrentUser } from 'providers/UserProvider';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { type Document } from 'services/documents/entities/Document';
import usePreviewUrl from '../../editor/usePreviewUrl';
import { BetaBadge } from '../components/beta-badge';
import { RestoreDocumentAction } from '../documents-tab/document-actions/RestoreDocumentAction';
import { getDocumentLink } from '../utils/getDocumentLink';
import { NotifyObject, listUndoObserver } from '../utils/listUndoObserver';
import { useFormatDateTime } from '../utils/useFormatDateTime';
import './styles.less';

export type ListType = 'Documents' | 'Trash';

export type TrashListProps = {
  documentList: Document[];
  setDocumentList: (documents: Document[] | ((prev: Document[]) => Document[])) => Promise<void>;
  isLoading: boolean;
  listEmpty: ReactNode;
  onTrash: (documentId: string) => Promise<void>;
  onRestore: (documentId: string) => Promise<void>;
};

export function TrashList({ documentList, setDocumentList, listEmpty, isLoading, onTrash, onRestore }: TrashListProps) {
  const [highlightItemId, setHighlightItemId] = useState('');
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const previewUrlPrefix = usePreviewUrl();
  const { data: currentUserInfo } = useCurrentUser();
  const { formatDocumentDateTime } = useFormatDateTime();

  useEffect(() => {
    const observerId = listUndoObserver.subscribe({
      action: removeDocumentWhenUndoFromAnotherTab,
    });

    return () => {
      listUndoObserver.unsubscribe(observerId);
    };
  }, []);

  const removeDocumentWhenUndoFromAnotherTab = ({ listType, documentId }: NotifyObject) => {
    if (listType !== 'Trash') {
      setDocumentList((previousList) => previousList.filter((document) => document.id !== documentId));
    }
  };

  const handleOnListMouseEnter = (documentId: string) => {
    setHoveredItemId(documentId);

    if (!highlightItemId) {
      return;
    }
    setHighlightItemId('');
  };

  const handleOnListMouseLeave = () => {
    setHoveredItemId(null);
  };

  const formatStatusToClassName = (status: string) => status.toLowerCase().replace(' ', '-');

  return (
    <List
      className="pipeline__list"
      loading={isLoading}
      dataSource={documentList}
      locale={{ emptyText: listEmpty }}
      renderItem={(item) => {
        const isEditorv3 = item.editorVersion === 3;
        let assignedUser = '';
        if (item.assigneeUserFirstName && item.assigneeUserLastName) {
          assignedUser = item.assigneeUserFirstName + ' ' + item.assigneeUserLastName;
        }

        const assignedUserProfileImage = assignedUser === currentUserInfo.fullName ? currentUserInfo.avatar : null;

        const assignedStandaloneContactFullName =
          item.prospectFirstName && item.prospectLastName && `${item.prospectFirstName} ${item.prospectLastName}`;

        const assignedProspect = item.companyName || assignedStandaloneContactFullName || '-';

        const tooltip = assignedUser;
        const isHovered = hoveredItemId === item.id;
        const documentLink = getDocumentLink({
          documentId: item.id,
          status: item.status,
          editorVersion: item.editorVersion,
          isLocked: item.isLocked,
          previewUrlPrefix,
        });

        return (
          <Link
            to={documentLink}
            key={item.id}
            id={item.id}
            data-testid={`pipelineItem${item.id}`}
            className={`document-version-${item.editorVersion}`}
          >
            <List.Item
              className={`${formatStatusToClassName(item.status)} ${highlightItemId === item.id ? ' item-highlight' : ''}`}
              onMouseEnter={() => handleOnListMouseEnter(item.id)}
              onMouseLeave={handleOnListMouseLeave}
              data-testid={`list-item-${item.id}`}
              id={`list-item-${item.id}`}
              onClick={(event) => event.stopPropagation()}
            >
              <List.Item.Meta
                title={
                  <div className="document-title-container">
                    {isEditorv3 ? <BetaBadge /> : null}
                    <div className="document-title">{item.title}</div>
                  </div>
                }
                description={assignedProspect}
              ></List.Item.Meta>
              <div className={`document-details ${isHovered && isEditorv3 ? 'hidden' : ''}`}>
                <div className="document-created-date">{formatDocumentDateTime(item.created_at)}</div>
                <Tooltip placement={'bottom'} title={tooltip}>
                  <div className="assigned_user">
                    <Avatar
                      userFirstName={item.assigneeUserFirstName}
                      userLastName={item.assigneeUserLastName}
                      profileImage={assignedUserProfileImage}
                    />
                  </div>
                </Tooltip>
                <div className="document-status-container">
                  <Chip
                    label={item.status}
                    variant="filled"
                    className={`document-status-chip ${formatStatusToClassName(item.status)}`}
                    size="small"
                    sx={{
                      '& .MuiChip-label': {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                    }}
                  />
                </div>
              </div>

              <div className={`document-actions ${isHovered ? '' : 'hidden'}`} data-testid="document-actions">
                {isEditorv3 && (
                  <RestoreDocumentAction documentToRestore={item} setDocumentList={setDocumentList} trash={onTrash} restore={onRestore} />
                )}
              </div>
            </List.Item>
            <Divider className="document-divider" />
          </Link>
        );
      }}
    />
  );
}
