import { Box, Fade, ListItem, ListItemText, Typography } from '@mui/material';
import { useState } from 'react';
import { type Document } from 'services/documents/entities/Document';
import { BetaChip } from '../components/BetaChip';
import { DocumentAvatar } from '../components/DocumentAvatar';
import { DocumentStatusChip } from '../components/DocumentStatusChip';
import { statusColors } from '../utils/statusColors';
import { useFormatDateTime } from '../utils/useFormatDateTime';
import { useGoToDocument } from '../utils/useGoToDocument';
import { MoreDocumentAction } from './document-actions/MoreDocumentAction';

interface DocumentListItemProps {
  document: Document;
  children: React.ReactNode;
  enableStatuses: boolean;
  refreshDocumentList: () => Promise<void>;
}

export function DocumentListItem({ document, enableStatuses, refreshDocumentList, children }: DocumentListItemProps) {
  const [isHovered, setIsHovered] = useState(false);
  const { formatDocumentDateTime } = useFormatDateTime();
  const { handleGoToDocument } = useGoToDocument({ document });

  const assignedStandaloneContactFullName =
    document.prospectFirstName && document.prospectLastName && `${document.prospectFirstName} ${document.prospectLastName}`;
  const assignedProspect = document.companyName || assignedStandaloneContactFullName || '-';

  return (
    <ListItem
      id={document.id}
      key={document.id}
      tabIndex={0}
      data-testid={`list-item-${document.id}`}
      divider
      sx={{
        gap: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        '&:focus': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          outline: 'none',
        },
        borderLeft: `4px solid ${statusColors[document.status]}`,
      }}
      onClick={handleGoToDocument}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleGoToDocument();
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ListItemText
        sx={{ flex: '1 1 200px' }}
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <BetaChip />
            <Typography variant="body1" fontWeight={600} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
              {document.title}
            </Typography>
          </Box>
        }
        secondary={
          <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {assignedProspect}
          </Typography>
        }
      />
      <Box sx={{ flex: '0 1 100px' }}>
        {!isHovered && (
          <Typography variant="labelSmall" textAlign="right" whiteSpace="nowrap">
            {formatDocumentDateTime(document.created_at)}
          </Typography>
        )}
      </Box>
      <Box sx={{ flex: '0 1 50px' }}>
        <DocumentAvatar document={document} />
      </Box>
      <Box sx={{ flex: '0 1 150px', display: 'flex', justifyContent: 'flex-end' }}>
        <Fade in={!isHovered}>
          <Box>{!isHovered && <DocumentStatusChip status={document.status} />}</Box>
        </Fade>
        <Box onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', flexDirection: 'row' }}>
          {isHovered && children}
          {isHovered && enableStatuses && (
            <MoreDocumentAction
              documentId={document.id}
              documentStatus={document.status}
              documentTitle={document.title}
              refreshDocumentList={refreshDocumentList}
              onMenuClose={() => setIsHovered(false)}
            />
          )}
        </Box>
      </Box>
    </ListItem>
  );
}
