import { Avatar, Tooltip, Typography } from '@mui/material';
import { useCurrentUser } from 'providers/UserProvider';
import { type Document } from 'services/documents/entities/Document';

export const DocumentAvatar = ({ document }: { document: Document }) => {
  const { data: currentUserInfo } = useCurrentUser();

  const assignedUser = `${document.assigneeUserFirstName || ''} ${document.assigneeUserLastName || ''}`.trim();

  const currentUserAvatar = assignedUser === currentUserInfo.fullName ? currentUserInfo.avatar : null;
  const validUserProfileImage = !currentUserAvatar?.includes('img/blank.gif') ? currentUserAvatar : null;

  const initials = `${document.assigneeUserFirstName?.[0] || ''}${document.assigneeUserLastName?.[0] || ''}`.toUpperCase();

  if (!validUserProfileImage && !initials) {
    return null;
  }

  return (
    <Tooltip title={assignedUser}>
      <Avatar
        sx={(theme) => ({ bgcolor: '#C5E7FF', color: theme.palette.primary.main })}
        alt="Avatar Profile Image"
        src={validUserProfileImage || undefined}
      >
        <Typography variant="labelSmall">{initials}</Typography>
      </Avatar>
    </Tooltip>
  );
};
