import { FileCopyOutlined as FileCopyIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { type Document } from 'services/documents/entities/Document';
import { useDuplicateDocument, type UseDuplicateDocumentProps } from './useDuplicateDocument';

type DuplicateDocumentActionProps = UseDuplicateDocumentProps & { documentToDuplicate: Document };

export function DuplicateDocumentAction({
  documentToDuplicate,
  setDocumentList,
  viewNewlyDuplicatedDocument,
}: DuplicateDocumentActionProps) {
  const { t } = useTranslation();
  const { duplicateDocument } = useDuplicateDocument({
    documentToDuplicate,
    setDocumentList,
    viewNewlyDuplicatedDocument,
  });

  return (
    <Tooltip title={t('pipeline.tabs.document_list.more_actions.duplicate_document_tooltip')}>
      <IconButton onClick={duplicateDocument} data-testid="duplicate-button">
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
}
