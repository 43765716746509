import { Chip, Typography } from '@mui/material';

export function BetaChip() {
  return (
    <Chip
      label={
        <Typography variant="body2" fontWeight={600}>
          Beta
        </Typography>
      }
      color="secondary"
      size="small"
      sx={{ marginRight: 0.5, fontSize: 12, fontWeight: 600, paddingTop: 0, paddingBottom: 0, height: 20 }}
    />
  );
}
