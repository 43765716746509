import { type Document } from 'services/documents/entities/Document';
import { listUndoObserver } from '../../utils/listUndoObserver';

interface UseTrashRestore {
  listType: 'Documents' | 'Trash';
  document: Document;
  setDocumentList: (documents: Document[] | ((prev: Document[]) => Document[])) => Promise<void>;
  trash: (documentId: string) => Promise<void>;
  restore: (documentId: string) => Promise<void>;
}

interface HandlerCallbacks {
  onSuccess?: () => void;
  onFailure?: () => void;
}

export function useTrashRestore({ listType, document, setDocumentList, trash, restore }: UseTrashRestore) {
  const removeDocumentFromList = () => {
    setDocumentList((previousList) => previousList.filter((doc) => doc.id !== document.id));
  };

  const restoreDocumentToList = () => {
    setDocumentList((previousList) => [...previousList, document]);
  };

  const handleProceed = async ({ onSuccess, onFailure }: HandlerCallbacks) => {
    removeDocumentFromList(); //before the API call to avoid delay
    try {
      if (listType === 'Documents') {
        await trash(document.id);
      } else {
        await restore(document.id);
      }
      listUndoObserver.notify({ listType, documentId: document.id });
      onSuccess?.();
    } catch (error) {
      restoreDocumentToList();
      onFailure?.();
    }
  };

  const handleUndo = async ({ onSuccess, onFailure }: HandlerCallbacks) => {
    restoreDocumentToList(); //before the API call to avoid delay
    try {
      if (listType === 'Documents') {
        await restore(document.id);
      } else {
        await trash(document.id);
      }
      onSuccess?.();
    } catch (error) {
      removeDocumentFromList();
      onFailure?.();
    }
  };

  return { handleProceed, handleUndo };
}
